import React from 'react'

import SRModal from "../modal/modal"

const HelpUr = () => (
    <SRModal title={"You need help?"} text={"You need help?"}>
        <p>
Any questions?<br/>
Get in touch with us. We are more than happy to help you!<br/>
<a href="mailto:registration-theunion@urevent.fr">registration-theunion@urevent.fr</a>
    </p>
    </SRModal>
)
SRModal.propTypes = {    
    text:       "You need help?",
    title:      "You need help?",
}
export default HelpUr
