import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { FaPenAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import {onChangeByName} from "../../redux/registration/registrationAction"
import {selectTicket} from "../../redux/registration/registrationAction"

import {
    submitDocuments,
    userToDocuments,
} from "../../redux/registration/registrationAction"
import {isDefined} from "../../utils/data"

const ProfessionalInput = ({value, onChange, valueTextInput, error, disabled}) => {
    const dispatch   = useDispatch()
    const categories = ["Affected community","Civil society", "Employee", "Government", "NGO", "Nurse", "Physician", "Public Health worker", "Researcher", "Student", "Other"]
    const valueIsOther = value !== "" ? categories.filter(ctg => ctg !== "Other").indexOf(valueTextInput) === -1 : false

    const [textVisible, setTextVisible] = useState(valueIsOther)
    const [inputValue, setInputValue]   = useState(valueIsOther ? valueTextInput : value)

    useEffect(() => {
        if(valueIsOther) {
            setInputValue(valueTextInput)
            setTextVisible(true)
        }
        else {
            dispatch(onChangeByName("registered_profession_category", valueTextInput))
            dispatch(onChangeByName("professionalCtgSelect", valueTextInput))
            setInputValue(valueTextInput)
            setTextVisible(false)
        }
    }, [valueTextInput])

    const onChangeProfessional = (e) => {
        dispatch(onChangeByName(e.target.name, e.target.value))
        if (e.target.value === "Other") {
            setInputValue("Please fill in")
            setTextVisible(true)
            dispatch(onChangeByName("registered_profession_category", "Please fill in."))
        }
        else {
            setTextVisible(false)
            dispatch(onChangeByName("registered_profession_category", e.target.value))
            dispatch(selectTicket(1))
        }
    }



    const onChangeText = e => {
        onChange(e)
        setInputValue(e.target.value)
    }

    return (
        <div className={"col-12 col-md-6 input-group"}>
            <label><FaPenAlt/></label>
            <select className="form-control" name={"professionalCtgSelect"} value={value} onChange={e => onChangeProfessional(e)} disabled={disabled} >
                <option value="" disabled selected>Professional Category *</option>
                <option>Affected community</option>
                <option>Civil society</option>
                <option>Employee</option>
                <option>Government</option>
                <option>NGO</option>
                <option>Nurse</option>
                <option>Physician</option>
                <option>Public Health worker</option>
                <option>Researcher</option>
                <option>Student</option>
                <option selected={valueIsOther}>Other</option>
            </select>
            <input className="form-control" name={"registered_profession_category"} placeholder={"text"} value={inputValue} onChange={e => onChangeText(e)} required style={{display: textVisible ? "block" : "none"}} disabled={disabled}/>
            {error !== "" ?
                <div className="invalid-tooltip">{error}</div>
                : ""}
        </div>
    )
}

ProfessionalInput.propTypes = {
    value:      PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    valueTextInput: PropTypes.string.isRequired,
    error:      PropTypes.string.isRequired,
    disabled:   PropTypes.bool,
}

ProfessionalInput.defaultProps = {
    disabled: false,
}

export default ProfessionalInput
